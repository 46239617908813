<template>
    <v-navigation-drawer :value="isDrawerOpen" app floating width="274" class="app-navigation-menu" :right="$vuetify.rtl" @input="val => $emit('update:is-drawer-open', val)">
        <!-- Navigation Header -->
        <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
            <router-link to="/member" class="d-flex align-center text-decoration-none">
                <!--<v-img
                              :src="require('@/assets/images/logos/logo.svg')"
                              max-height="30px"
                              max-width="30px"
                              alt="logo"
                              contain
                              eager
                              class="app-logo me-3"
                            ></v-img>-->
                <v-slide-x-transition>
                    <h2 class="app-title text--primary">
                        <img src="@/assets/images/logos/logo.png" style="max-width:100%;" alt="Logo">
    
                    </h2>
                </v-slide-x-transition>
            </router-link>
        </div>
    
        <!-- Navigation Items -->
        <v-list dense class="vertical-nav-menu-items pr-4">
            <nav-menu-group title="Main Menu" :icon="icons.mdiMenu">
                <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiViewDashboard"></nav-menu-link>
                <nav-menu-link title="Users" :to="{ name: 'users' }" :icon="icons.mdiAccount"></nav-menu-link>
                <nav-menu-link title="Organizer Profile" :to="{ name: 'organizer' }" :icon="icons.mdiStore"></nav-menu-link>
                <nav-menu-link title="Events" :to="{ name: 'events' }" :icon="icons.mdiCalendar"></nav-menu-link>
                <nav-menu-link title="Add-ons" :to="{ name: 'addons' }" :icon="icons.mdiPlaylistPlus"></nav-menu-link>
                <nav-menu-link title="Orders" :to="{ name: 'orders' }" :icon="icons.mdiReceipt"></nav-menu-link>
                <nav-menu-link title="Resale Center" :to="{ name: 'resales' }" :icon="icons.mdiBankTransfer"></nav-menu-link>
                <nav-menu-link title="Reports" :to="{ name: 'reports' }" :icon="icons.mdiChartBar"></nav-menu-link>
                <nav-menu-link title="Ticket Template" :to="{ name: 'tickettemp' }" :icon="icons.mdiTicketConfirmation"></nav-menu-link>
                <nav-menu-link title="Admin Account" :to="{ name: 'admins' }" :icon="icons.mdiAccountStar"></nav-menu-link>
                <nav-menu-link title="Site Control" :to="{ name: 'sitecontrol' }" :icon="icons.mdiSitemap"></nav-menu-link>
            </nav-menu-group>
            <template v-if="show_extend">
                      <!-- <v-list-item v-if="page_name=='user_detail'">
                        <p>User : {{local_share_data.id}}</p>
                      </v-list-item> -->
            <!-- <template v-if="page_name=='event_add' || page_name=='event_detail'">
                        <nav-menu-link title="Overview" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Orders & Attendees" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Reports" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Event Information" :to="{ name: 'event_detail' }" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Checkout Questions" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Payments & Fees" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Embeddable Widgets" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Discount Codes" :icon="icons.mdiSitemap"></nav-menu-link>
                        <nav-menu-link title="Promote" :icon="icons.mdiSitemap"></nav-menu-link>
</template> -->
</template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {
    mdiMenu,
    mdiViewDashboard,
    mdiAccount,
    mdiSitemap,
    mdiReceipt,
    mdiStore,
    mdiTicketConfirmation,
    mdiPlaylistPlus,
    mdiBankTransfer,
    mdiHomeOutline,
    mdiStorefront,
    mdiCog,
    mdiWalletGiftcard,
    mdiAlphaTBoxOutline,
    mdiTextBoxSearchOutline,
    mdiTextBoxSearch,
    mdiCardAccountDetails,
    mdiFileDocumentMultiple,
    mdiEyeOutline,
    mdiFileDocumentMultipleOutline,
    mdiTicketPercent,
    mdiCreditCardOutline,
    mdiTable,
    mdiFileOutline,
    mdiFormSelect,
    mdiBellRing,
    mdiAccountCogOutline,
    mdiCircleSmall,
    mdiCarSettings,
    mdiAccountStar,
    mdiCommentQuestion,
    mdiPencil,
    mdiMotorbike,
    mdiRoadVariant,
    mdiBookOpenVariant,
    mdiCar3Plus,
    mdiCarCog,
    mdiBookEditOutline,
    mdiCarEstate,
    mdiCarChildSeat,
    mdiNewBox,
    mdiFileQuestion,
    mdiBell,
    mdiChartBar,
    mdiCalendar,
    mdiBookMultipleOutline,
} from '@mdi/js'

import NavMenuGroup from './components/NavMenuGroup.vue'

import NavMenuLink from './components/NavMenuLink.vue'
// import { store } from '../../../views/tickcats/store.js'
import store from "../../../store"

export default {
    components: {
        NavMenuGroup,
        NavMenuLink,
    },
    props: {
        isDrawerOpen: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            page_name: "",
            page_id: -1,
            local_share_data: null,
            show_extend: false,
            open: true,
        };
    },
    setup() {
        return {
            icons: {
                mdiMenu,
                mdiViewDashboard,
                mdiAccount,
                mdiSitemap,
                mdiStore,
                mdiPlaylistPlus,
                mdiReceipt,
                mdiTicketConfirmation,
                mdiAccountStar,
                mdiBankTransfer,
                mdiHomeOutline,
                mdiWalletGiftcard,
                mdiTextBoxSearchOutline,
                mdiTextBoxSearch,
                mdiCog,
                mdiCommentQuestion,
                mdiTicketPercent,
                mdiAlphaTBoxOutline,
                mdiCardAccountDetails,
                mdiEyeOutline,
                mdiCreditCardOutline,
                mdiTable,
                mdiFileDocumentMultipleOutline,
                mdiFileDocumentMultiple,
                mdiBellRing,
                mdiStorefront,
                mdiFileOutline,
                mdiFormSelect,
                mdiAccountCogOutline,
                mdiCircleSmall,
                mdiCarSettings,
                mdiPencil,
                mdiMotorbike,
                mdiRoadVariant,
                mdiBookOpenVariant,
                mdiCar3Plus,
                mdiCarCog,
                mdiBookEditOutline,
                mdiCarEstate,
                mdiCarChildSeat,
                mdiNewBox,
                mdiFileQuestion,
                mdiBell,
                mdiChartBar,
                mdiCalendar,
                mdiBookMultipleOutline,
            },
        }
    },
    // watch:() => {route.params, fetchData, { immediate: true }},
    computed: {
        share_data() {
            return store.state.share_data;
        }
    },
    watch: {
        // "$route.currentRoute.path"(newValue){
        //   console.log(newValue)
        // }
        $route(to, from) {
            console.log("route watch")
            // console.log(from)
            // console.log(to)
            this.page_name = to.name;
            console.log(this.page_name)
            switch (this.page_name) {
                case "user_detail":
                case "organizer_detail":
                case "admin_detail":
                case "event_detail":
                    this.show_extend = true;
                    break;
                case "events":
                case "event_add":
                    this.show_extend = true;
                    this.local_share_data = null;
                    break;
                default:
                    this.show_extend = true;
                    this.local_share_data = null;
                    break;
            }
            console.log("show_extend")
            console.log(this.show_extend)
            // page_id = to.para
        },
        share_data(to, from) {
            console.log("share data watch")
            // console.log(from)
            console.log(to)
            this.local_share_data = to;
        
              console.log(this.$route.name)
              this.page_name = this.$route.name
            switch (this.page_name) {
                case "user_detail":
                case "organizer_detail":
                case "admin_detail":
                case "event_detail":
                    this.show_extend = true;
                    break;
                case "events":
                case "event_add":
                    this.show_extend = true;
                    this.local_share_data = null;
                    break;
                default:
                    this.show_extend = true;
                    this.local_share_data = null;
                    break;
            }
            console.log("show_extend")
            console.log(this.show_extend)
        }
    },
    methods: {
        fetchData: function(data) {
            console.log(data)
        },
        pageChangeSideInfo() {

        },
    },
    created() {
        console.log(this.$route.params)
        // console.log(this.$route.params.id)
        // if (this.$route.params.id != undefined) {
        // }
    }
}
</script>

<style>
.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 32px;
}

.v-list .v-list-item__title {
    font-size: 14.5px;
    font-weight: 500;
}

.v-list .v-list-group__items .v-list-item__title {
    font-size: 13.25px;
    font-weight: 400;
    margin-left: 16px;
}

.v-list-item__icon {
    font-size: 12px;
    min-width: unset;
    width: 20px;
    height: 20px;
}

.v-list-item__icon .v-icon__svg {
    width: 20px;
    height: 20px;
}
</style>

<style lang="scss" scoped>
.app-title {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: .05rem !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
    transition: all 0.18s ease-in-out;
    .v-navigation-drawer--mini-variant & {
        transform: translateX(-4px);
    }
}

@include theme(app-navigation-menu) using ($material) {
    background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
    .v-list-item {
        &.vertical-nav-menu-link {
            ::v-deep .v-list-item__icon {
                .v-icon {
                    transition: none !important;
                }
            }
        }
    }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
    .upgrade-banner {
        position: absolute;
        bottom: 13px;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
