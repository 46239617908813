<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import draggable from "vuedraggable";
// import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    draggable,
    // VueEditor,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>

<style lang="css">
/* @import "~vue2-editor/dist/vue2-editor.css"; */

/* Import the Quill styles you want */
/* @import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css'; */
</style>