import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
Vue.use(VueCookies)

const getters = {
    login_status: state => state.authenticated,
}

export default new Vuex.Store({
    state: {
        authenticated: false,
        darkmode: false,
        share_data: null,
    },
    mutations: {
        setShareData(state, data) {
            state.share_data = data
        },
        setLoginData(state) {
            state.authenticated = true
        },
        resetData(state) {
            state.login_data = [];
            state.login_status = false
            Object.assign(state, {
                authenticated: false,
                darkmode: false,
            })
        },
    },
    actions: {
        getData({ commit }, data) {
            commit(data.method)
        },
        reset({ commit }, data) {
            commit(data.method)
        },
    },
    getters,
    modules: {},
    plugins: [createPersistedState()],
})